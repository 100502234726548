<template>
  <v-layout
    justify-center
    align-center
    style="height: 100vh"
  >
    <v-card
      color="primary"
      style="width: 80vw; height: 200px"
    >
      <v-card-text class="pt-3 white--text">
        <div class="text-center white--text font-weight-light p-10">
          <v-icon
            size="80px"
            color="white"
          >
            {{ icon.mdiWhatsapp }}
          </v-icon>
        </div>

        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
        <div
          style="font-size: 24px"
          class="text-center white--text font-weight-light"
        >
          Super Whatsapp
        </div>
        <div
          class="text-center"
          style="font-size: 12px"
        >
          <span class="mx-1">
            <v-icon
              small
              color="white"
            >{{ icon.mdiLock }}</v-icon>
          </span>
          Load Data
        </div>
      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>
// eslint-disable-next-line lines-around-comment
// import custom
import { mdiLock, mdiWhatsapp } from '@mdi/js'

import pouchDbMixin from '../../mixins/pouchDbMixin'

export default {
  name: 'Redirect',
  mixins: [pouchDbMixin],
  data() {
    return {
      overlay: true,
      valueProgress: 0,
      status: true,
      icon: {
        mdiLock,
        mdiWhatsapp,
      },
      finish: 0,
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    currentRouteParams() {
      return this.$route.params.page
    },
    currentRouteIdList() {
      return this.$route.params.idlist
    },
  },
  watch: {
    finish() {
      if (this.finish === 4) {
        if (!this.status) {
          this.$store.commit('auth/setSuccessSync', false)
        } else {
          this.$store.commit('auth/setSuccessSync', true)
        }

        this.redirectPage()
      }
    },
  },
  async mounted() {
    await this.replicatePouchDb()
  },
  methods: {
    async replicatePouchDb() {
      this.finish = 4
      this.status = true
      this.replicateRedirect(process.env.VUE_APP_DB_SETTING_NAME, this.user, success => {
        //console.log(process.env.VUE_APP_DB_SETTING_NAME, ' SUCCESS')
        if (!success) this.status = false
        this.finish += 1
      })
      this.valueProgress = 20
      this.replicateRedirect(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, success => {
        //console.log(process.env.VUE_APP_DB_CONTACTS_NAME, ' SUCCESS')
        if (!success) this.status = false
        this.finish += 1
      })
      this.valueProgress = 40
      this.replicateRedirect(process.env.VUE_APP_DB_BROADCASTS_NAME, this.user, success => {
        //console.log(process.env.VUE_APP_DB_BROADCASTS_NAME, ' SUCCESS')
        if (!success) this.status = false
        this.finish += 1
      })
      this.valueProgress = 70
      this.replicateRedirect(process.env.VUE_APP_DB_HISTORY_NAME, this.user, success => {
        //console.log(process.env.VUE_APP_DB_HISTORY_NAME, ' SUCCESS')
        if (!success) this.status = false
        this.finish += 1
      })
      this.valueProgress = 100
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    redirectPage() {
      switch (this.currentRouteParams) {
        case 'channels':
          this.$router.push({ name: 'channels' }, () => {})
          break
        case 'messageTemplate':
          this.$router.push({ name: 'messageTemplate' }, () => {})
          break
        case 'broadcast-list':
          this.$router.push({ name: 'broadcast-list' }, () => {})
          break
        case 'broadcast-draft':
          this.$router.push({ name: 'broadcast-draft' }, () => {})
          break
        case 'broadcast-scheduled':
          this.$router.push({ name: 'broadcast-scheduled' }, () => {})
          break
        case 'broadcast-statistic':
          this.$router.push({ name: 'broadcast-statistic' }, () => {})
          break
        case 'contacts-group-list':
          this.$router.push({ name: 'contacts-group-list' }, () => {})
          break
        case 'contacts-list':
          this.$router.push({ name: 'contacts-list' }, () => {})
          break
        case 'contacts-list-custom':
          this.$router.push({ name: 'contacts-list-custom', params: { idlist: this.currentRouteIdList } }, () => {})
          break
        case 'label-list':
          this.$router.push({ name: 'label-list' }, () => {})
          break
        case 'inbox':
          this.$router.push({ name: 'inbox' }, () => {})
          break
        case 'contact-inbox':
          this.$router.push({ name: 'contact-inbox' }, () => {})
          break
        case 'subscriptions':
          this.$router.push({ name: this.user.package ? 'billings' : 'subscriptions' }, () => {})
          break
        case 'billings':
          this.$router.push(
            {
              name: this.user.package ? 'billings' : 'subscriptions',
            },
            () => {},
          )
          break
        case 'invoice':
          this.$router.push({ name: 'invoice' }, () => {})
          break
        case 'operators-list':
          this.$router.push({ name: 'operators-list' }, () => {})
          break
        case 'replies-history':
          this.$router.push({ name: 'replies-history' }, () => {})
          break
        case 'operator-statistic':
          this.$router.push({ name: 'operator-statistic' }, () => {})
          break
        default:
          this.$router.push({ name: 'channels' }, () => {})
      }
    },
  },
}
</script>
